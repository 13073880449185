<template>
  <div  id="mycard" class="card">
    <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">Group:</label>
        <div class="col">
          <select  class="form-control" v-model="ldgp" >
            <option value="0">-</option>
            <option v-for="group in groups" v-bind:value="group.code">
              {{ group.name }}
            </option>
          </select>
        </div>

        <!--<label class="col-md-1 col-form-label">From:</label>-->
        <!--<div class="col-md-2">-->
          <!--<input type="date"  class="form-control" placeholder="From Date"  v-model="from_date" >-->
        <!--</div>-->

        <label class="col-md-1 col-form-label">As On:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="Upto Date" v-model="upto_date" >
        </div>
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData">Show</button>
      </div>

    </div>

    <div class="table-responsive">
      <table  id="summary-table" class="table table-xs table-columned table-hover" data-target=".context-table">
        <thead>
        <tr>
          <th style="width: 50px;">S.No</th>
          <th>Description</th>
          <th style="width: 150px;text-align: right">Debit</th>
          <th style="width: 150px;text-align: right">Credit</th>
        </tr>
        </thead>
        <tbody   >
        </tbody>
        <tfoot style="background-color: lightgrey;" class="font-weight-semibold">

        </tfoot>
      </table>
    </div>




    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <a href="#" class="dropdown-item" ><i class="icon-file-eye"></i>Preview</a>

      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="225" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline" style="background-color: darkgrey;color: white;padding: 10px;">
            <h3 class="card-title">Date Range</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-9">
                <input type="date" class="form-control"  v-model="from_date" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">To:</label>
              <div class="col-md-9">
                <input type="date" class="form-control" v-model="upto_date" />
              </div>
            </div>


            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Filter<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'GroupSummaryView',
    store,
    data () {
      return {
        mytable: {},
        groups: [],
        current_ibr : localStorage.getItem('branch_code'),
        ldgp: 0,
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
      }
    },
    created () {

    },
    filters:{
      formatDate(val){
        return val.slice(0, 10).split('-').reverse().join('-');
      },
      indianFormat (value) {
        if(value !== 0)
          return value.toFixed(2);
        else
          return '';
      },
      formatDetail(ldgp, val){
        var result = '<table>';
        val.forEach(function (v) {
          if(v.ledger.code != ldgp){
            result +=  '<tr><td>'+ v.ledger.name +'</td></tr>';
          }
        });
        result += '<table>';
        return result;
      }

    },
    mounted () {
      let self = this;
      self.$data.ldgp = 0;
      self.$data.mytable = $('#summary-table').children('tbody');


      self.grouploadLedgerGroups();

      $('.table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text()

          if ($(e.target).text() === 'Refresh') {
            // self.loadData()
          }
        }
      });

    },
    methods: {

      showFilter(){
        this.$modal.show('filter-window');
      },
      blockCard(){
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      unblockCard(){
        $('#mycard').unblock();
      },
      grouploadLedgerGroups () {
        let self = this

        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        }


        //fetch the Ledgers
        self.$data.groups = []
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledgergroup/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {
            if (_.isArray(resp.data)) {
              self.$data.groups = resp.data
            }
          } else {
            swal({ title: 'Oops', text: resp.message, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        })
      },

      loadData () {
        let self = this
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$modal.hide('filter-window');

        self.blockCard();

        $("#summary-table > tbody tr").remove();
        $("#summary-table > tfoot tr").remove();
        self.$data.trans = [];

        let debit = 0.0;
        let credit = 0.0;

        if (self.$data.ldgp > 0) {

          fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/groupsummary/${self.$data.ldgp}/?ibr=${self.$data.current_ibr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted) {


              if (_.isArray(resp.data)) {
                self.$data.trans = resp.data;

                resp.data.forEach(function (trn,idx) {

                  if (trn.balance > 0 ) {
                    var row = `
                      <tr>
                          <td>${idx + 1}</td>
                          <td>${trn.ledger}</td>
                          <td style="width: 150px;text-align: right"></td>
                          <td style="width: 150px;text-align: right">${indianFormat(Math.abs(trn.balance))}</td>
                      </tr>
                    `;
                    self.$data.mytable.append(row);
                    credit += Math.abs(trn.balance);
                  }else if (trn.balance < 0 ) {
                    var row = `
                      <tr>
                          <td>${idx + 1}</td>
                          <td>${trn.ledger}</td>
                          <td style="width: 150px;text-align: right">${indianFormat(Math.abs(trn.balance))}</td>
                          <td style="width: 150px;text-align: right"></td>
                      </tr>
                    `;
                    self.$data.mytable.append(row);
                    debit += Math.abs(trn.balance);
                  }
                });

              }

              //Displays the Total
              $("#summary-table > tfoot tr").remove();
              var footer = $('#summary-table').children('tfoot');
              var row = `
                      <tr>
                          <td></td>
                          <td style="text-align: right">Total</td>
                          <td style="width: 150px;text-align: right">${indianFormat(debit)}</td>
                          <td style="width: 150px;text-align: right">${indianFormat(credit)}</td>
                      </tr>
                    `;
              footer.append(row);

            } else {
              swal({ title: 'Oh noes!', text: resp.message, type: 'error' })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
          }).finally(function () {

            self.unblockCard();
          })
        } else {
          self.unblockCard();
        }

      },



    }
  }

</script>

<style scoped>

</style>
